<template>
  <div>
    <a-modal
      width="800px"
      height="700px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑殡仪馆"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="殡仪馆名称">
          <a-input-search
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            enter-button="定位"
            @search="location"
          />
        </a-form-item>
        <a-form-item label="地图地址">
          <a-select
            v-decorator="['address', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入地图地址' }
              ]
            }]"
            :loading="locationOptions.length === 0"
            @change="changLocation"
          >
            <a-select-option
              v-for="(location,index) in locationOptions"
              :key="index"
              :value="index"
            >
              {{ location.title }} {{ location.address ? '('+location.address+')' : '' }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="经度">
          <a-input-number
            style="width: 100%;"
            :min="-180"
            :max="180"
            :step="0.000001"
            :precision="6"
            disabled
            v-decorator="['longitude', {
              rules: [
                { required: true, message: '请输入经度' } ]
            }]"
          />
        </a-form-item>
        <a-form-item label="纬度">
          <a-input-number
            style="width: 100%;"
            :min="-90"
            :max="90"
            :step="0.000001"
            :precision="6"
            disabled
            v-decorator="['latitude', {
              rules: [
                { required: true, message: '请输入纬度' }
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="自定义地址">
          <a-input
            v-decorator="['custom_address', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入自定义地址' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="介绍">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
      <div id="container" />
    </a-modal>
  </div>
</template>

<script>
import { updateFuneralHome } from '@/api/funeral_home'
import { locationSearch } from '@/api/map'

export default {
  name: 'EditFuneralHome',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'funeral' }),
      submitting: false,
      data: {},
      locationOptions: [],
      address: '',
      marker: {},
      amap: {},
      createMap: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.initFormData()
    this.locationOptions.push({ address: '', title: this.record.address, location: { lng: this.record.longitude, lat: this.record.latitude }})
  },
  methods: {
    initFormData() {
      this.data = Object.assign({},
        {
          name: this.record.name,
          address: 0,
          longitude: this.record.longitude,
          latitude: this.record.latitude,
          remark: this.record.remark,
          custom_address: this.record.custom_address
        })
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
        this.form.setFieldsValue({ address: this.record.address })
      })
    },
    onSearch() {
      const name = this.form.getFieldValue('name')
      locationSearch({ keyword: name, page_size: 10 }).then(res => {
        if (res.code === 0 && res.data.status === 0) {
          this.locationOptions.push(...res.data.data)
        }
      })
    },
    changLocation(val) {
      const location = this.locationOptions[val]
      this.address = location.title + '(' + location.address + ')'
      this.data = Object.assign({}, { longitude: location.location.lng, latitude: location.location.lat })
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
        this.form.setFieldsValue({ address: this.address })
      })
      this.initMarkers(location.title, location.location.lat, location.location.lng)
    },
    initMarkers(title, lat, lng) {
      const currentLatLng = new TMap.LatLng(lat, lng)
      this.marker.updateGeometries([
        {
          styleId: 'current',
          id: '1',
          position: currentLatLng,
          content: title // 标注点文本
        }
      ])
      this.amap.setCenter(currentLatLng)
    },
    initMap() {
      const center = new TMap.LatLng(31.981234, 120.894518)
      const map = new TMap.Map(document.getElementById('container'), {
        center: center,
        zoom: 13
      })
      const marker = new TMap.MultiMarker({
        map: map,
        styles: {
          current: new TMap.MarkerStyle({
            width: 34,
            height: 46,
            anchor: { x: 36, y: 32 },
            src: 'https://res.anxiaotang.com/assets/d273876b-2874-4d50-bf7a-7d623845c0e8',
            color: '#333',
            size: 16,
            direction: 'bottom',
            strokeColor: '#fff',
            offset: { x: 0, y: 0 },
            strokeWidth: 2
          })
        },
        geometries: []
      })
      this.marker = marker
      this.amap = map
      this.createMap = false
    },
    location() {
      this.onSearch()
      if (this.createMap === true) {
        this.initMap()
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const formData = Object.assign(values, { address: this.address })
          updateFuneralHome(this.record.id, formData).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
